import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop, Eyebrow } from '@styles/global'
import RichText from '@components/RichText'
import Section from '@components/Section'
import ModuleStyles from '@styles/ModuleStyles'
import Button from './Button'
import isArabic from '@utils/isArabic'

const FreeText = ({ className, content, id }) => {
	return (
		<Wrap className={className} bgColor={content.background?.value} id={id}>
			<Container>
				<Eyebrow>{content.eyebrow}</Eyebrow>
				{content?.title &&
					<Title dir={isArabic(content?.title) ? 'rtl' : 'ltr'}>{content?.title}</Title>
				}
				{content?.largeText &&
					<Large  dir={isArabic(content?.largeText) ? 'rtl' : 'ltr'} className='para'>{content?.largeText}</Large>
				}
				<ModuleStyles>
					<RichText moduleStyles content={content.text}/>
				</ModuleStyles>
				{content?.button &&
					<BottomButton 
						link={content?.button.link} 
						outline={content?.button.style === 'box' || !content?.button.style}
						icon={content?.button.style === 'arrow' && 'Rarr'}
						flex={content?.button.style === 'arrow' }
						css={css`
							min-width: ${content?.button.style === 'arrow' ? '0px' : '265px'};
							padding: ${content?.button.style === 'arrow' ? 'var(--xxs) 0' : '6px var(--xs)'};
					`}
					>
						{content?.button.text}
					</BottomButton>
				}
			</Container>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: var(--xxxl) 0 var(--xl);
	background-color: ${props => props.bgColor ? props.bgColor : 'var(--white)'};
`
const Container = styled.div`
	grid-column: span 8;
	${desktop}{
		grid-column: span 10;
	}
	${tablet}{
		grid-column: span 12;
	}
`

const Title = styled.h3`
	margin-bottom: var(--m);
`

const Large = styled.p`
	margin-bottom: var(--m);
`

const BottomButton = styled(Button)`
	margin-top: var(--xs);
`

FreeText.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default FreeText